import React, {
  useEffect,
  useState
} from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  Row
} from "reactstrap";
import {
  faChevronUp,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine
} from 'recharts';
import { groupBy, reverse, meanBy } from 'lodash';

function StateStatistics(props) {
  
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => setIsOpen(!isOpen);

  const [planFailedCount, setPlanFailedCount] = useState(0);
  const [applyFailedCount, setApplyFailedCount] = useState(0);
  const [discardedCount, setDiscardedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [noChangesCount, setNoChangesCount] = useState(0);
  
  const [planTimes, setPlanTimes] = useState([]);
  const [planTimeAvg, setPlanTimeAvg] = useState();
  const [applyTimes, setApplyTimes] = useState([]);
  const [applyTimeAvg, setApplyTimeAvg] = useState();
  
  
  useEffect(() => {
    const grouped = groupBy(props.recentlyCompletedPlans, 'status');
    
    setPlanFailedCount(grouped.PLAN_FAILED ? grouped.PLAN_FAILED.length : 0);
    setApplyFailedCount(grouped.APPLY_FAILED ? grouped.APPLY_FAILED.length : 0);
    setDiscardedCount(grouped.DISCARDED ? grouped.DISCARDED.length : 0);
    setNoChangesCount(grouped.NO_CHANGES ? grouped.NO_CHANGES.length : 0);
    setCompletedCount(grouped.COMPLETED ? grouped.COMPLETED.length : 0);
    
    const pt = reverse(props.recentlyCompletedPlans.filter(plan => plan.startedAt && plan.planCompletedAt).map(plan => ({
      name: new Date(plan.startedAt).toLocaleString(),
      value: (new Date(plan.planCompletedAt).getTime() - new Date(plan.startedAt).getTime()) / 1000
    })));
    
    setPlanTimes(pt);
    setPlanTimeAvg(Math.round(meanBy(pt, 'value')))
    
    const at = reverse(props.recentlyCompletedPlans.filter(plan => plan.planApprovedAt && plan.applyCompletedAt).map(plan => ({
      name: new Date(plan.startedAt).toLocaleString(),
      value: (new Date(plan.applyCompletedAt).getTime() - new Date(plan.planApprovedAt).getTime()) / 1000
    })));

    setApplyTimes(at);
    setApplyTimeAvg(Math.round(meanBy(at, 'value')))
    
  }, [props.recentlyCompletedPlans]);
  
  return <Card>
    <CardHeader>
      <CardTitle className='m-0 d-flex justify-content-between'>
        <h5>Stats</h5>
        <Button className='stretched-link' size='sm' close onClick={toggleOpen}><FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown}/></Button>
      </CardTitle>
    </CardHeader>
    <Collapse isOpen={isOpen}>
      <CardBody>
        {
          props.recentlyCompletedPlans && props.recentlyCompletedPlans.length > 0 ? <Row>
            <Col md={12} className='text-center'><h6>{`Last ${props.recentlyCompletedPlans.length} plans`}</h6></Col>
            <Col md={4} sm={6} xs={12} className='text-center mb-2'>
              <h6>Plan Results</h6>
              <ResponsiveContainer width="100%" aspect={1}>
                <PieChart>
                  <Pie dataKey="value" data={[
                    { name: 'Completed', value: completedCount, fill: '#01932f' },
                    { name: 'No Changes', value: noChangesCount, fill: '#008F8F' },
                    { name: 'Discarded', value: discardedCount, fill: '#201E1F' },
                    { name: 'Plan Failed', value: planFailedCount, fill: '#fc632d' },
                    { name: 'Apply Failed', value: applyFailedCount, fill: '#FF4000' }]} innerRadius='70%' outerRadius='100%' fill="#82ca9d" />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Col>
            { planTimes.length > 0 ? <Col md={4} sm={6} xs={12} className='text-center mb-2'>
              <h6>Plan Duration (seconds)</h6>
              <ResponsiveContainer width="100%" aspect={1}>
                <LineChart data={planTimes}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <ReferenceLine y={planTimeAvg} label={{ fontSize: '18px', fontWeight: 'bold', value: `Average (${planTimeAvg}s)`, position: 'insideBottomRight' }} stroke="#008F8F" />
                  <Line dataKey="value" stroke='#008F8F' strokeWidth={3} />
                  <XAxis dataKey="name" hide={true} />
                  <YAxis domain={[(min) => Math.max(0, Math.ceil((min - 30) / 10) * 10), (max) => Math.ceil((max + 30) / 10) * 10]}/>
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </Col> : undefined }
            { applyTimes.length > 0 ? <Col md={4} sm={6} xs={12} className='text-center mb-2'>
              <h6>Apply Duration (seconds)</h6>
              <ResponsiveContainer width="100%" aspect={1}>
                <LineChart data={applyTimes}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <Line dataKey="value" stroke='#008F8F' strokeWidth={3} />
                  <ReferenceLine y={applyTimeAvg} label={{ fontSize: '18px', fontWeight: 'bold', value: `Average (${applyTimeAvg}s)`, position: 'insideBottomRight' }} stroke="#008F8F" />
                  <XAxis dataKey="name" hide={true} />
                  <YAxis domain={[(min) => Math.max(0, Math.ceil((min - 30) / 10) * 10), (max) => Math.ceil((max + 30) / 10) * 10]}/>
                  <Tooltip />
                </LineChart>
              </ResponsiveContainer>
            </Col> : undefined}
            
          </Row> : <p>Not enough plans yet. Keep planning with Brainiac to see trends, statistics, and more.</p>
        }
      </CardBody>
    </Collapse>
  </Card>
}

export default StateStatistics;
