import {getPropsForStatus} from "../../helpers/plan";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  Col,
  Row
} from "reactstrap";
import PlanProgress
  from "../plan-progress";
import React
  from "react";

function PlanProgressCard(props) {
  return <Card className={`mb-2 card-${getPropsForStatus(props.status).color}`}><CardBody>
    {props.commitHash ? <CardSubtitle tag="h6" className="mb-2 text-muted">
      {props.commitHash.slice(0, 6)}: {props.commitMessage}
    </CardSubtitle> : <div/>}
    <CardText tag="h6" className='text-muted'>
      <Row>
        {props.commitUser ? <Col md="4">
          <img alt={`Thumbnail for user ${props.commitUser}`} className='commit-thumb mr-2' src={props.commitUserThumbnailUrl} />
          {props.commitUser}
        </Col> : <div/>}
        <Col md="8" className="text-right align-self-center">
          {props.planDetails ? <span>
            {props.planDetails.creates ? ` ${props.planDetails.creates} to create.` : ''}
            {props.planDetails.updates ? ` ${props.planDetails.updates} to update.` : ''}
            {props.planDetails.deletions ? ` ${props.planDetails.deletions} to delete.` : ''}
            {props.planDetails.replaces ? ` ${props.planDetails.replaces} to replace.` : ''}
            {props.planDetails.outputChanges ? ' Root module has output changes.' : ''}
          </span> : undefined}
        </Col>
      </Row>
      
    </CardText>
    <PlanProgress status={props.status}/>
  </CardBody></Card>;
}

export default PlanProgressCard;
