import React, {
  useEffect,
  useState
} from 'react';
import {BreadcrumbItem} from "reactstrap";
import {
  Link,
  useLocation
} from "react-router-dom";
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {getOrgFromPath} from "../../../helpers/ids";

function OrganizationBreadcrumb () {
  const { getAccessTokenSilently } = useAuth0();
  
  const location = useLocation();
  
  const [organization, setOrganization] = useState();
  
  useEffect(() => {
    const loadOrg = async (id) => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getOrganizationByIdUrl = `https://${domain}/organizations/${id}`;

        const metadataResponse = await fetch(getOrganizationByIdUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        const org = await metadataResponse.json();

        setOrganization(org);

      } catch (error) {
        console.log(error);
      }
    };

    (async () => {
      const organizationId =  getOrgFromPath(location.pathname);

      // runs on location, i.e. route, change
      if (!organizationId || organizationId.length < 36) {
        setOrganization(null);
      } else {
        await loadOrg(organizationId);
      }
    })();
  }, [location.pathname, getAccessTokenSilently]);
  
  return organization ? <BreadcrumbItem><Link to={`/organizations/${organization.id}/states`}>{organization.name}</Link></BreadcrumbItem> : <BreadcrumbItem>...</BreadcrumbItem>
}

export default OrganizationBreadcrumb;
