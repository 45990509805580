import {useAuth0} from "@auth0/auth0-react";
import fetch
  from "node-fetch";
import {useEffect} from "react";

const useMountEffect = (func) => {
  return useEffect(func, []);
}

const useMakeRequest = () => {
  const {
    getAccessTokenSilently
  } = useAuth0();
  
  return async (req) => {
    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";
    
    const accessToken = await getAccessTokenSilently({
      audience: 'https://auth0-jwt-authorizer'
    });
    
    const path = req.path.startsWith('/') ? req.path : `/${req.path}`;
    
    const url = `https://${domain}${path}`

    const response = await fetch(url, {
      headers: {
        Authorization: accessToken,
      },
      method: req.method.toUpperCase()
    });
    
    return response.json();
  };
};

export { useMountEffect, useMakeRequest };
