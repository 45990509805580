import React, { useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from 'reactstrap';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {Spinner} from 'reactstrap';

function NewOrganizationMemberPage() {
  const [email, setEmail] = useState('');
  const [roles, setRoles] = useState('');
  const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { organizationId } = useParams();

  const {
    getAccessTokenSilently
  } = useAuth0();

  const onSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const newOrganizationMemberUrl = `https://${domain}/organizations/${organizationId}/users`;

      const metadataResponse = await fetch(newOrganizationMemberUrl, {
        method: 'POST',
        body: JSON.stringify({
          email,
          roles: roles.split(','),
          isOrganizationAdmin
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      console.log(metadataResponse);

      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      history.push(`/organizations/${organizationId}/members`);
      
    } catch (error) {
      console.log(error);
      
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  }
  
  const handleRolesChange = (e) => {
    setRoles(e.target.value.trim());
  }
  
  const handleIsOrganizationAdminChange = (e) => {
    setIsOrganizationAdmin(e.target.checked);
  }
  
  return loading ? <Spinner/> : <div>
    <h3>New Organization Member</h3>
    <Form onSubmit={onSubmit}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="name">Email</Label>
            <Input onChange={handleEmailChange} value={email} type="text" name="email" id="email" placeholder="Email" />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="roles">Roles</Label>
            <Input onChange={handleRolesChange} value={roles} type="text" name="roles" id="roles" placeholder="Roles (comma-separated)" />
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup className='ml-3'>
            <Label for="is-organization-admin">
              <Input name="is-organization-admin" id="is-organization-admin" onChange={handleIsOrganizationAdminChange} type="checkbox" />
              Organization Admin
            </Label>
          </FormGroup>
        </Col>
        <Col md={12}><Button>Create</Button></Col>
      </Row>
    </Form>
  </div>
}

export default NewOrganizationMemberPage;
