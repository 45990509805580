import React, { useState } from "react";

import {Button, Form, FormGroup, Label, Input, Spinner} from 'reactstrap';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  useHistory,
  useParams
} from "react-router-dom";

function OrganizationTfTokenPage() {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  
  const history = useHistory();

  const { organizationId } = useParams();

  const {
    getAccessTokenSilently
  } = useAuth0();

  const onSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const addOrUpdateTfTokenUrl = `https://${domain}/organizations/${organizationId}/tf-token`;

      const metadataResponse = await fetch(addOrUpdateTfTokenUrl, {
        method: 'POST',
        body: JSON.stringify({
          token
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      history.push(`/organizations/${organizationId}/states`);

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTokenChange = (e) => {
    setToken(e.target.value);
  }

  return loading ? <Spinner/> : <div>
    <h3>Terraform Token</h3>
    <p>Using <a href="https://app.terraform.io">Terraform Cloud</a> for your state management simplifies collaboration without locking you into any proprietary systems.</p>
    <p>You're also free to use other backends (S3, Azure, etc.). Those can be configured directly within your repository's Terraform configuration files.</p>
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="token">Token</Label>
        <Input onChange={handleTokenChange} value={token} type="password" name="token" id="token" placeholder="Terraform Cloud Token" />
      </FormGroup>
      <Button>Update</Button>
    </Form>
  </div>
}

export default OrganizationTfTokenPage;
