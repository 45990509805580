import React from 'react';

import { Progress } from 'reactstrap';
import {getPropsForStatus} from "../../helpers/plan";

function PlanProgress(props) {
  return <div
    className='mb-2'
    style={{height: '30px'}}
  >
    <Progress
      tag='h5'
      className='h-100'
      animated={getPropsForStatus(props.status).animated}
      color={getPropsForStatus(props.status).color}
      value={getPropsForStatus(props.status).value}
    >
      {getPropsForStatus(props.status).message}
    </Progress>
  </div>
}

export default PlanProgress;
