import React, { useState } from "react";
import {Link} from "react-router-dom";
import {
  Spinner,
  Button,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row
} from 'reactstrap';
import {
  useMakeRequest,
  useMountEffect
} from "../../helpers/request";

function OrganizationsPage() {
  
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const makeRequest = useMakeRequest();
  
  useMountEffect(() => {
    (async () => {
      try {
        const orgs = await makeRequest({
          path: '/organizations',
          method: 'GET'
        })
        setOrganizations(orgs);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  });
  
  return loading ? <Spinner/> : <div>
    <div className="d-flex justify-content-between">
      <h3>Organizations</h3>
      <div>
        <Button color='primary' className='mt-2 mb-2' tag={Link} outline to={`/organizations/new`}>New Organization</Button>
      </div>
    </div>

    {
      organizations.length === 0 ? (
        <p>You don't have access to any organizations right now. Contact your administrator if you need increased access.</p>
      ) : undefined
    }

    <Row>{
      organizations.map(organization => (
        <Col key={organization.id} md="6" className="mb-3">
          <Card>
            <CardBody>
              <CardTitle tag="h5"><Link to={`/organizations/${organization.id}/states`}>{organization.name}</Link></CardTitle>
              <CardText><Link to={`/organizations/${organization.id}/members`}>Manage Members</Link></CardText>
              <CardText><Link to={`/organizations/${organization.id}/version-control`}>Version Control</Link></CardText>
              <CardText><Link to={`/organizations/${organization.id}/tf-token`}>Terraform Token</Link></CardText>
            </CardBody>
          </Card>
        </Col>
      ))
    }</Row>
  </div>
}

export default OrganizationsPage;
