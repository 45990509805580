import React, { useState, useEffect } from "react";
import {
  Link,
  useParams
} from "react-router-dom";
import {
  Spinner,
  Row,
  Card,
  CardBody,
  CardText,
  Col,
  Button
} from "reactstrap";
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";

function StateEnvironmentPage() {
  const { organizationId, stateId } = useParams();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [environmentVariables, setEnvironmentVariables] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getEnvironmentVariablesForState = async () => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getEnvironmentVariablesForStateUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/environment-variables`;

        const metadataResponse = await fetch(getEnvironmentVariablesForStateUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        if (metadataResponse.status >= 300) {
          throw new Error('error from API');
        }

        const states = await metadataResponse.json();

        setEnvironmentVariables(states);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getEnvironmentVariablesForState();
  }, [getAccessTokenSilently, organizationId, stateId]);
  
  const deleteVariable = async (id) => {
    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";
    
    setLoading(true);

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const getEnvironmentVariablesForStateUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/environment-variables/${id}`;

      const metadataResponse = await fetch(getEnvironmentVariablesForStateUrl, {
        method: 'DELETE',
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('error from API');
      }

      setEnvironmentVariables(environmentVariables.filter(envVar => envVar.id !== id));
      
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  return loading ? <Spinner/> : <div>
    <div className="d-flex justify-content-between">
      <h3>Environment Variables</h3>
      <div>
        <Button color='primary' className='mt-2 mb-2' tag={Link} outline to={`/organizations/${organizationId}/states/${stateId}/environment-variables/new`}>New Environment Variable</Button>
      </div>
    </div>
    {
      environmentVariables.length === 0 ? <p>No variables yet. Environment variables added here will be available to your state during plan/apply runs.</p> : undefined
    }
    <Row>
      {
        environmentVariables.map(envVar =>(
          <Col md='12' key={envVar.id} className='mb-3'>
            <Card className='border-primary'>
              <CardBody>
                <CardText>
                  <p><strong>{envVar.name}</strong> = {envVar.encrypted ? 'SENSITIVE' : envVar.value}</p>
                  <Link href="#" onClick={() => {
                    deleteVariable(envVar.id)
                  }}>
                    Delete
                  </Link>
                </CardText>
              </CardBody>
            </Card>
          </Col>
        ))
      }
    </Row>
  </div>
}

export default StateEnvironmentPage;
