import React, {
  useEffect,
  useState
} from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner
} from "reactstrap";
import fetch
  from "node-fetch";
import {useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {faBell} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const INITIAL_EVENTS_MAPPING = {
  'PLAN_QUEUED': {
    name: 'Plan Queued',
    enabled: false
  },
  'PLAN_RUNNING': {
    name: 'Plan Running',
    enabled: false
  },
  'PLAN_NO_CHANGES': {
    name: 'Plan Completed Without Changes',
    enabled: false
  },
  'PLAN_AWAITING_APPROVAL': {
    name: 'Plan Awaiting Approval',
    enabled: false
  },
  'PLAN_FAILED': {
    name: 'Plan Failed',
    enabled: false
  },
  'PLAN_CANCELLED': {
    name: 'Plan Cancelled',
    enabled: false
  },
  'PLAN_APPROVED': {
    name: 'Plan Approved',
    enabled: false
  },
  'APPLY_COMPLETED': {
    name: 'Apply Completed',
    enabled: false
  },
  'APPLY_FAILED': {
    name: 'Apply Failed',
    enabled: false
  }
}

function SlackNotifications() {
  const { organizationId, stateId } = useParams();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(false);
  const [enabledBadge, setEnabledBadge] = useState(false);
  const [includeLogs, setIncludeLogs] = useState(false);
  const [name, setName] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');
  const [eventsMapping, setEventsMapping] = useState({
    ...INITIAL_EVENTS_MAPPING
  });
  
  useEffect(() => {
    const getSlackNotificationSettings = async () => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getSlackNotificationSettingsUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/slack-notifications`;

        const metadataResponse = await fetch(getSlackNotificationSettingsUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        if (metadataResponse.status >= 300) {
          throw new Error('error from API');
        }

        const slackNotificationSettings = await metadataResponse.json();

        if (slackNotificationSettings.name) {
          setName(slackNotificationSettings.name);
        }
        
        if (slackNotificationSettings.webhookUrl) {
          setWebhookUrl(slackNotificationSettings.webhookUrl);
        }
        
        if (slackNotificationSettings.includeLogs) {
          setIncludeLogs(slackNotificationSettings.includeLogs);
        }
        
        if (slackNotificationSettings.createdAt) {
          setEnabled(true);
          setEnabledBadge(true);
        }
        
        if (slackNotificationSettings.events) {
          setEventsMapping(e => {
            slackNotificationSettings.events.forEach(event => {
              e[event].enabled = true
            })
            
            return e;
          });
        }
        
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    
    getSlackNotificationSettings();
  }, [getAccessTokenSilently, organizationId, stateId]);

  const handleEnabledChange = (e) => {
    setEnabled(e.target.checked);
  }
  
  const handleNameChange = (e) => {
    setName(e.target.value);
  }
  
  const handleWebhookUrlChange = (e) => {
    setWebhookUrl(e.target.value);
  }
  
  const handleEventChange = (e) => {
    const em = {
      ...eventsMapping
    };
    
    em[e.target.name].enabled = e.target.checked;
    
    setEventsMapping(em);
  }

  const handleIncludeLogsChange = (e) => {
    setIncludeLogs(e.target.checked);
  }
  
  const onSubmit = async (e) => {
    e.preventDefault();
    
    setUpdating(true);
    
    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const putSlackNotificationSettingsUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/slack-notifications`;

      const metadataResponse = await fetch(putSlackNotificationSettingsUrl, {
        method: 'POST',
        body: JSON.stringify({
          name,
          webhookUrl,
          events: Object.keys(eventsMapping).filter(em => eventsMapping[em].enabled),
          enabled,
          includeLogs
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('error from API');
      }
      
      if (!enabled) {
        setWebhookUrl('');
        setName('');
        setEventsMapping(INITIAL_EVENTS_MAPPING);
        setEnabledBadge(false);
        setIncludeLogs(false);
      } else {
        setEnabledBadge(true);
      }

      setUpdating(false);
    } catch (error) {
      console.log(error);
      setUpdating(false);
    }
  }
  
  
  return loading ? <Spinner/> : <Card className='mb-2'>
    <CardBody>
      <h5><FontAwesomeIcon className='mr-2' icon={faBell} title='Slack'/> Slack <Badge tag='p' color={enabledBadge ? 'success' : 'warning'}>{enabledBadge ? 'Enabled' : 'Disabled'}</Badge></h5>
      <Form onSubmit={onSubmit}>
        <FormGroup check>
          <Label for="enabled">
            <Input name="enabled" id="enabled" checked={enabled} onChange={handleEnabledChange} type="checkbox" />
            Enabled
          </Label>
        </FormGroup>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input onChange={handleNameChange} value={name} type="text" name="name" id="name" placeholder="Name" />
        </FormGroup>
        <FormGroup>
          <Label for="webhook-url">Webhook URL</Label>
          <Input onChange={handleWebhookUrlChange} value={webhookUrl} type="text" name="webhook-url" id="webhook-url" placeholder="Slack webhook URL" />
        </FormGroup>
        {
          Object.keys(eventsMapping).map(e =>
            <FormGroup check key={e}>
              <Label for={e}>
                <Input name={e} id={e} checked={eventsMapping[e].enabled} onChange={handleEventChange} type="checkbox" />
                {eventsMapping[e].name}
              </Label>
            </FormGroup>
          )
        }
        <FormGroup check>
          <Label for="include-logs">
            <Input name="include-logs" id="include-logs" checked={includeLogs} onChange={handleIncludeLogsChange} type="checkbox" />
            Include Logs in Notifications (Coming Soon)
          </Label>
        </FormGroup>
        
        <Button color='primary' disabled={updating}>Save</Button>
      </Form>
    </CardBody>
  </Card>
}

export default SlackNotifications;
