import React  from 'react';
import {BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import {
  getOrgFromPath,
  getStateFromPath
} from "../../../helpers/ids";

function EnvironmentVariablesBreadcrumb () {
  return <BreadcrumbItem><Link to={`/organizations/${getOrgFromPath()}/states/${getStateFromPath()}/environment-variables`}>Environment Variables</Link></BreadcrumbItem>
}

export default EnvironmentVariablesBreadcrumb;
