import React, { useState } from "react";

import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import {Spinner} from 'reactstrap';

function OrganizationsPage() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  
  const history = useHistory();

  const {
    getAccessTokenSilently
  } = useAuth0();
  
  const onSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const getOrganizationsByIdUrl = `https://${domain}/organizations`;

      const metadataResponse = await fetch(getOrganizationsByIdUrl, {
        method: 'POST',
        body: JSON.stringify({
          name,
          description
        }),
        headers: {
          Authorization: accessToken,
        },
      });
      
      console.log(metadataResponse);
      
      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      history.push('/organizations');

      
      
    } catch (error) {
      console.log(error);
    }
  };
  
  const handleNameChange = (e) => {
    
    console.log(setName);
    setName(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    console.log(setDescription);
    setDescription(e.target.value);
  }
  
  return loading ? <Spinner/> : <div>
    <h3>New Organization</h3>
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="name">Name</Label>
        <Input onChange={handleNameChange} value={name} type="text" name="name" id="name" placeholder="Give it a descriptive name" />
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <Input onChange={handleDescriptionChange} value={description} type="textarea" name="text" id="description" placeholder="What it does, who it's for, etc." />
      </FormGroup>
      <Button>Create</Button>
    </Form>
  </div>
}

export default OrganizationsPage;
