import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.scss';

import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom';

import {useAuth0} from "@auth0/auth0-react";
import {
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  ButtonGroup
} from 'reactstrap';

import Pusher from 'pusher-js';

import Ansi from "ansi-to-react";
import {
  getPlan,
  applyPlan,
  discardPlan,
  getPropsForStatus,
  retryPlan,
  preemptPlan
} from "../../helpers/plan";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faBan,
  faRedo,
  faFastForward,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'
import PlanProgressCard
  from "../../components/plan-progress-card";
import {
  merge
} from "lodash";

function PlanDetailsPage() {
  const { organizationId, stateId, planId } = useParams();
  const history = useHistory();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [activeTab, setActiveTab] = useState('plan');
  
  const [plan, setPlan] = useState();
  const [loading, setLoading] = useState(true);
  const [applyLoading, setApplyLoading] = useState(false);
  const [discardLoading, setDiscardLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);
  const [preemptLoading, setPreemptLoading] = useState(false);
  const [liveLoading, setLiveLoading] = useState(false);
  const [followLogs, setFollowLogs] = useState(false);

  const [realTimePlanLogs, setRealTimePlanLogs] = useState('');
  const [realTimeApplyLogs, setRealTimeApplyLogs] = useState('');
  
  const planRef = useRef();
  const logsRef = useRef({
    PLAN: {},
    APPLY: {}
  });
  const realTimeLogsRef = useRef({
    PLAN: {},
    APPLY: {}
  });
  const followLogsRef = useRef(false);
  
  const fullPlanLoaded = useRef(false);
  const fullApplyLoaded = useRef(false);
  const latestChunk = useRef(0);

  const updatePlanLogs = (type) => {
    if (type === 'PLAN') {
      setRealTimePlanLogs(Object.values(merge(logsRef.current[type], realTimeLogsRef.current[type])).join('\n'));
    } else if (type === 'APPLY') {
      setRealTimeApplyLogs(Object.values(merge(logsRef.current[type], realTimeLogsRef.current[type])).join('\n'));
    } else {
      console.warn(`Unexpected type ${type}`);
    }
  }

  const getPlanLogs = useCallback(async (shouldReRunAutomatically = true) => {
    setLiveLoading(true);
    
    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });
      
      const planResult = await getPlan({
        accessToken,
        organizationId,
        stateId,
        planId,
        includePlanLogs: !fullPlanLoaded.current,
        includeApplyLogs: !fullApplyLoaded.current,
        afterChunk: latestChunk.current
      });

      planRef.current = planResult;
      setPlan(planResult);
      
      for (const chunk of planResult.planLogs) {
        logsRef.current['PLAN'][chunk.index] = chunk.log;
      }

      for (const chunk of planResult.applyLogs) {
        logsRef.current['APPLY'][chunk.index] = chunk.log;
      }

      updatePlanLogs('PLAN');
      updatePlanLogs('APPLY');

      setLoading(false);
      setLiveLoading(false);
      
    } catch (error) {
      console.log(error);

      setLoading(false);
      setLiveLoading(false);
    }
  }, [getAccessTokenSilently, organizationId, planId, stateId]);
  
  useEffect(() => {
    getPlanLogs(true);
  }, [getPlanLogs]);
  
  const subscribePusherPlanLogs = () => {
    const pusher = new Pusher('e8df177cd4aa52d77e83', {
      cluster: 'us3'
    });

    const channel = pusher.subscribe("planUpdate");

    channel.bind(planId, (data) => {
      console.log(data);
      
      if (data.messageType === 'RUN_LOGS') {
        if (data.isStartOfChunk) {
          realTimeLogsRef.current[data.type][data.index] = data.logs;
        } else if (realTimeLogsRef.current[data.type][data.index]) {
          realTimeLogsRef.current[data.type][data.index] += `\n${data.logs}`;
        } else {
          console.warn('Could not find a home for incoming log. This usually means that a plan was loaded while a chunk was being streamed', data);
        }

        updatePlanLogs(data.type);

        if (followLogsRef.current) {
          window.scrollTo(0, document.body.scrollHeight);
        }
        
      } else if (data.messageType === 'STATUS_UPDATE') {
        planRef.current = {
          ...planRef.current,
          status: data.status
        }
        
        if (['AWAITING_APPROVAL', 'NO_CHANGES', 'PLAN_FAILED'].includes(data.status)) {
          if (Object.keys(realTimeLogsRef.current['PLAN']).length === 0) {
            console.log('No logs in plan, fetching');
            
            getPlanLogs();
          }
        }
        
        if (['COMPLETED', 'APPLY_FAILED'].includes(data.status)) {
          if (Object.keys(realTimeLogsRef.current['APPLY']).length === 0) {
            console.log('No logs in apply, fetching');
            
            getPlanLogs();
          }
        }
        
        setPlan(planRef.current);
      }
    });
    
    return channel;
  }
  
  useEffect(() => {
    const channel = subscribePusherPlanLogs();

    return () => {
      try {
        channel.unsubscribe();
        channel.unbind_all();
        
        console.debug('unloaded pusher');
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line
  }, []);
  
  const applyPlanAction = async () => {
    setLiveLoading(true);
    setApplyLoading(true);
    
    const accessToken = await getAccessTokenSilently({
      audience: 'https://auth0-jwt-authorizer'
    });
    
    await applyPlan(organizationId, stateId, planId, accessToken);
    
    await getPlanLogs(false);
    
    setLiveLoading(false);
    setApplyLoading(false);
    
    setActiveTab('apply');
  }

  const discardPlanAction = async () => {
    setLiveLoading(true);
    setDiscardLoading(true);
    
    const accessToken = await getAccessTokenSilently({
      audience: 'https://auth0-jwt-authorizer'
    });
    
    await discardPlan(organizationId, stateId, planId, accessToken);

    await getPlanLogs(false);
    
    setLiveLoading(false);
    setDiscardLoading(false);
  }

  const retryPlanAction = async () => {
    setLiveLoading(true);
    setRetryLoading(true);

    const accessToken = await getAccessTokenSilently({
      audience: 'https://auth0-jwt-authorizer'
    });

    const newPlan = await retryPlan(organizationId, stateId, planId, accessToken);

    history.push('/empty');
    history.replace(`/organizations/${organizationId}/states/${stateId}/plans/${newPlan.id}`);
  }

  const preemptPlanAction = async () => {
    setLiveLoading(true);
    setPreemptLoading(true);

    const accessToken = await getAccessTokenSilently({
      audience: 'https://auth0-jwt-authorizer'
    });
    
    try {
      await preemptPlan(organizationId, stateId, planId, accessToken);

      history.push('/empty');
      history.replace(`/organizations/${organizationId}/states/${stateId}/plans/${planId}`);
    } catch (e) {
      console.error(e);
    }
  }
  
  const toggleFollowLogs = () => {
    followLogsRef.current = !followLogsRef.current;

    setFollowLogs(followLogsRef.current);
  }
  
  const bottomOfPage = () => {
    window.scrollTo(0, document.body.scrollHeight);
  }
  
  const topOfPage = () => {
    window.scrollTo(0, 0);
  }

  return loading ? <Spinner/> : <div>
    <div className="d-flex justify-content-between">
      <h3 className='mr-3'>Plan {plan ? plan.id.slice(0, 6) : ''}</h3>
      <div>
        {
          getPropsForStatus(plan.status).isFinalState
            ? <Button title='Retry plan' outline onClick={async () => retryPlanAction()} disabled={retryLoading} color="primary" className='mr-1'>{liveLoading ? <Spinner size='sm'/> : <FontAwesomeIcon icon={faRedo}/>}</Button>
            : <div>
              {
                plan.status === 'QUEUED'
                  ? <Button title='Discard previous plans and start this one immediately' outline onClick={async () => preemptPlanAction()} disabled={preemptLoading} color="primary" className='mr-1'>{liveLoading ? <Spinner size='sm'/> : <FontAwesomeIcon icon={faFastForward}/>}</Button>
                  : undefined
              }
              <Button title='Approve plan' outline onClick={async () => applyPlanAction()} disabled={plan.status !== 'AWAITING_APPROVAL' || applyLoading} color="success" className='mr-1'>{liveLoading ? <Spinner size='sm'/> : <FontAwesomeIcon icon={faCheck}/>}</Button>
              <Button title='Reject plan' outline onClick={async () => discardPlanAction()} disabled={!['QUEUED', 'AWAITING_APPROVAL'].includes(plan.status) || discardLoading} color="danger" className='mr-1'>{liveLoading ? <Spinner size='sm'/> : <FontAwesomeIcon icon={faBan}/>}</Button>
            </div>
        }
      </div>
    </div>
    {plan.retryOfPlanId ? <h6>(Retry of plan <Link to={`/organizations/${organizationId}/states/${stateId}/plans/${plan.retryOfPlanId}`} >{plan.retryOfPlanId.slice(0, 6)}</Link>)</h6> : undefined}
    {
      plan.sourceInfo ?
        <PlanProgressCard
          commitHash={plan.sourceInfo.commitHash}
          status={plan.status}
          commitMessage={plan.sourceInfo.commitMessage}
          commitUserThumbnailUrl={plan.sourceInfo.commitUserThumbnailUrl}
          commitUser={plan.sourceInfo.commitUser}
          planDetails={plan.planDetails}
        /> : <PlanProgressCard
          status={plan.status}
          planDetails={plan.planDetails}
        />
    }
    <ButtonGroup className='border mb-2'>
      <Button className='border-right' color={followLogs ? 'success' : 'default'} onClick={() => toggleFollowLogs()}>Follow logs</Button>
      <Button color="default" onClick={() => bottomOfPage()}>Bottom of page <FontAwesomeIcon className='pt-1' icon={faChevronDown}/></Button>
    </ButtonGroup>
    
    <Nav tabs>
      <NavItem>
        <NavLink href='#' className={activeTab === 'plan' ? 'active' : ''} onClick={() => setActiveTab('plan')}>Plan</NavLink>
      </NavItem>
      <NavItem>
        <NavLink href='#' disabled={!['APPLY_FAILED', 'COMPLETED', 'APPLY_IN_PROGRESS'].includes(plan.status)} className={activeTab === 'apply' ? 'active' : ''} onClick={() => setActiveTab('apply')}>Apply</NavLink>
      </NavItem>
    </Nav>
    <TabContent activeTab={activeTab}>
      <TabPane tabId='plan'>
        <div className="mb-2 p-3 bg-black">
          <pre className="text-white"><Ansi className="ansi-white-bg no-word-wrap">
            { realTimePlanLogs || 'Setting up isolated server and environment. This shouldn\'t take long.'}
          </Ansi></pre>
        </div>
      </TabPane>
      <TabPane tabId='apply'>
        <div className="mb-2 p-3 bg-black">
          <pre className="text-white"><Ansi className="ansi-white-bg no-word-wrap">
            {realTimeApplyLogs || 'Setting up isolated server and environment. This shouldn\'t take long.'}
          </Ansi></pre>
        </div>
      </TabPane>
    </TabContent>
    <ButtonGroup className='border mb-2'>
      <Button className='border-right' color={followLogs ? 'success' : 'default'} onClick={() => toggleFollowLogs()}>Follow logs</Button>
      <Button color="default" onClick={() => topOfPage()}>Top of page <FontAwesomeIcon className='pt-1' icon={faChevronUp}/></Button>
    </ButtonGroup>
  </div>;
}

export default PlanDetailsPage;
