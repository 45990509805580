import React, { useState, useEffect } from "react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {
  Button,
  Spinner
} from "reactstrap";
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import StateSettingsForm
  from "../../components/state-settings-form";
import {
  useMakeRequest
} from "../../helpers/request";

function StateSettings() {
  const { organizationId, stateId } = useParams();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState();
  const history = useHistory();
  const makeRequest = useMakeRequest();

  useEffect(() => {
    const getState = async () => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getStateUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}`;

        const metadataResponse = await fetch(getStateUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        if (metadataResponse.status >= 300) {
          throw new Error('error from API');
        }

        const st = await metadataResponse.json();

        setState({
          name: st.name,
          description: st.description,
          terraformVersion: st.terraformVersion,
          repoSlug: st.repo.repoSlug,
          workingDirectory: st.repo.workingDirectory,
          readRoles: st.readRoles.join(','),
          planRoles: st.planRoles.join(','),
          applyRoles: st.applyRoles.join(','),
          adminRoles: st.adminRoles.join(',')
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getState();
  }, [getAccessTokenSilently, organizationId, stateId]);
  
  const onSubmit = async (values) => {
    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const readRolesList = values.readRoles.trim() ? values.readRoles.split(",").map(role => role.trim()) : [];
      const planRolesList = values.planRoles.trim() ? values.planRoles.split(",").map(role => role.trim()) : [];
      const applyRolesList = values.applyRoles.trim() ? values.applyRoles.split(",").map(role => role.trim()) : [];
      const adminRolesList = values.adminRoles.trim() ? values.adminRoles.split(",").map(role => role.trim()) : [];

      const addStateUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/settings`;

      const metadataResponse = await fetch(addStateUrl, {
        method: 'PUT',
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          repoSlug: values.repoSlug,
          workingDirectory: values.workingDirectory,
          terraformVersion: values.terraformVersion,
          readRoles: readRolesList,
          planRoles: planRolesList,
          applyRoles: applyRolesList,
          adminRoles: adminRolesList
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      console.log(metadataResponse);

      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      history.push(`/organizations/${organizationId}/states/${stateId}`);

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  
  const destroyState = async () => {
    setLoading(true);
    
    try {
      const deletionResult = await makeRequest({
        path: `/organizations/${organizationId}/states/${stateId}`,
        method: 'DELETE'
      });
      
      console.debug(deletionResult);

      history.push(`/organizations/${organizationId}/states`);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  return loading ? <Spinner/> : <div>
    <h3>State Settings</h3>
    <StateSettingsForm
      initialValues={state}
      submitButtonText={'Update'}
      onSubmit={onSubmit}
    />
    
    <hr/>
    
    <div className='border border-danger p-3'>
      <h4>Destruction & Deletion</h4>
      <p><strong>Take caution!</strong> By continuing, you're requesting the deletion of the <strong>{state.name}</strong> state, which includes plan logs, configuration, notification settings, environment variables, and more.</p>
      <p><strong>Deleting a state in Brainiac does not delete the resources managed by the state!</strong> Delete the resources manually or run <code>terraform destroy</code> to delete underlying resources and avoid surprise bills.</p>
      <p>For more detail on how your data is managed, don't hesitate to <a href='mailto:me@ericzaporzan.com'>reach out</a>.</p>
      
      <Button onClick={destroyState} color='danger'>I understand, <strong>destroy {state.name}</strong></Button>
    </div>
    
  </div>
}

export default StateSettings;
