import React  from 'react';
import {BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import {getOrgFromPath} from "../../../helpers/ids";

function StatesBreadcrumb () {
  return <BreadcrumbItem><Link to={`/organizations/${getOrgFromPath()}/states`}>States</Link></BreadcrumbItem>
}

export default StatesBreadcrumb;
