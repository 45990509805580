import React from "react";
import {
  Container
} from "reactstrap";


function HelpPage() {
  return <Container className='p-4'>
    <h2 className='mb-4'>Help & Support</h2>
    <h4>Planned Work</h4>
    <ul>
      <li>A Terraform provider, to define states and other configuration programmatically.</li>
      <li>Bitbucket and Gitlab support.</li>
      <li>Better support for drift detection functionality.</li>
      <li>Self-service sign-up.</li>
      <li>Broader notification support.</li>
    </ul>
    <h4>Known issues</h4>
    <h5>Authentication/Authorization</h5>
    <ul>
      <li>Attempting to log in without an account will yield a generic "sorry, something has gone wrong" error. Brainiac is currently invite-only.</li>
      <li>User sessions (particularly in Safari) may not survive a page refresh if you've blocked cross-domain cookies. Brainiac uses Auth0 for a secure authentication experience.</li>
    </ul>
    <h5>Billing</h5>
    <ul>
      <li>Plan and apply durations in state statistics currently include provisioning time, which will ultimately not reflect on your bill.</li>
    </ul>
    <h4>Questions?</h4>
    <p><a href="mailto:me@ericzaporzan">Contact me</a> to report bugs, suggest features and enhancements, or to say hello. I sincerely appreciate your interest in Brainiac.</p>
  </Container>
}

export default HelpPage;
