import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import "./index.scss";
import 'typeface-josefin-sans';

import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-nrewvmyu.us.auth0.com"
      clientId="JUZBpioJActocyiO30y5A2Lh0JwQUonN"
      redirectUri={window.location.origin}
      audience="https://auth0-jwt-authorizer"
      cacheLocation={process.env.NODE_ENV === 'production' ? 'memory' : 'localstorage'} // Make local development a little less annoying in Safari. localstorage should not be used as a production option.
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
