import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import "./App.scss";

import Nav from "./components/nav";

import Home from './pages/home';
import {useAuth0} from "@auth0/auth0-react";
import {
  Container
} from "reactstrap";
import Breadcrumbs, {authedRoutes} from "./components/breadcrumbs";
import HelpPage
  from "./pages/help";

function App() {
  const {
    isLoading,
    isAuthenticated
  } = useAuth0();
  
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/help">
            <HelpPage />
          </Route>
          { isAuthenticated || isLoading ? (
            <Container className='mt-2'>
              <Breadcrumbs />
              <Route path="/organizations/:organizationId" />
              <Route path="/organizations/:organizationId/states/:stateId" />
              
              <Switch>{
                authedRoutes.map(route =>
                  <Route key={route.route} exact={true} path={route.route}>
                    {React.createElement(route.component, {})}                      
                  </Route>
                )
              }</Switch>
            </Container>
            ) : undefined }
          { !isLoading ? (
            <Route path="*">
              <Redirect to="/" />
            </Route>
          ) : undefined }
        </Switch>
      </div>
    </Router>
  );
}

export default App;
