import React, { useState } from "react";
import "./index.scss";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import {Link} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Logo from './logo-transparent.png';

function BrainiacNav() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    loginWithRedirect,
    logout,
    isAuthenticated,
    isLoading
  } = useAuth0();

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar className='navbar-dark' color="dark" light expand="md">
      <div className='container'>
        <NavbarBrand className='nav-brand' tag={Link} to="/">
          <img alt='Brainiac Logo' width="200px" src={Logo}/>
          {/*Brain<span className='text-warning navbar-brand-warning-text'>iac</span>*/}
          {/*<FontAwesomeIcon className='nav-icon ml-2' icon={faBolt}/>*/}
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mt-1 mr-auto" navbar>
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} to="/about">About</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*  <NavLink tag={Link} to="/pricing">Pricing</NavLink>*/}
            {/*</NavItem>*/}
            { isAuthenticated ? (
              <NavItem>
                <NavLink className='top-nav' tag={Link} to="/organizations">ORGANIZATIONS</NavLink>
              </NavItem>
            ): undefined}
            { isLoading ? undefined : isAuthenticated ? (
              <NavItem>
                <NavLink
                  className='top-nav'
                  href="#"
                  onClick={() => {
                    logout({ returnTo: window.location.origin });
                  }}
                >
                  LOGOUT
                </NavLink>
              </NavItem>
            ) : (
              <NavItem>
                <NavLink
                  className='top-nav'
                  href="#"
                  onClick={() => {
                    loginWithRedirect();
                  }}
                >
                  LOGIN/JOIN
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink className='top-nav' tag={Link} to="/help">HELP</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
}

export default BrainiacNav;
