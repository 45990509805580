import {
  getPlanStatusText,
  getPropsForStatus
} from "../../helpers/plan";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle
} from "reactstrap";
import {formatRelative} from "date-fns";
import {
  Link,
  useParams
} from "react-router-dom";
import React
  from "react";

function PlanCard(props) {
  const { organizationId, stateId } = useParams();
  
  const currentDate = new Date();
  
  return <Card className={`plan-card card-${getPropsForStatus(props.plan.status).color}`}>
    <CardBody>
      <CardTitle tag="h5">{ props.title ?? `Plan ${props.plan.id.slice(0, 6)}`}</CardTitle>
      <CardSubtitle tag="h6" className="mb-2 text-muted">{props.plan.sourceInfo
        ? `${props.plan.sourceInfo.commitMessage} | ${formatRelative(new Date(props.plan.createdAt), currentDate)} ${ props.plan.startedBy ? `by ${props.plan.sourceInfo.commitUser}` : '' }`
        : `Triggered ${formatRelative(new Date(props.plan.createdAt), currentDate)} ${ props.plan.startedBy ? `by ${props.plan.startedBy.name}` : '' }`
      }</CardSubtitle>
      
      <CardText>{getPlanStatusText(props.plan.status)}</CardText>
      <Link className='stretched-link' to={`/organizations/${organizationId}/states/${stateId}/plans/${props.plan.id}`} />
    </CardBody>
  </Card>
}

export default PlanCard;
