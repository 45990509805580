import './index.scss';

import React, { useEffect, useState, useRef, useCallback } from 'react';

import {
  Link,
  useHistory,
  useParams
} from 'react-router-dom';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {Button, Row, Col} from "reactstrap";
import {orderBy} from 'lodash';
import {Spinner} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import StateDetails
  from "../../components/state-details";
import PlanCard
  from "../../components/plan-card";

function PlansPage() {
  const { organizationId, stateId } = useParams();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [liveLoading, setLiveLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const lastEvaluatedKey = useRef('');
  const history = useHistory();

  const getPlansForState = useCallback(async (afterKey) => {
    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });
      
      const keyClause = afterKey ? `?startKey=${afterKey}` : ''

      const getPlansByStateIdUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans${keyClause}`;

      const metadataResponse = await fetch(getPlansByStateIdUrl, {
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('error from API');
      }

      const fetchedPlans = (await metadataResponse.json());

      setPlans(p => orderBy(p.concat(fetchedPlans.plans), ['createdAt'], ['desc']));
      
      lastEvaluatedKey.current = fetchedPlans.lastEvaluatedKey;
      
      if (lastEvaluatedKey.current) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }, [getAccessTokenSilently, stateId, organizationId]);
  
  const loadMore = async () => {
    setLiveLoading(true);
    
    if (lastEvaluatedKey.current) {
      await getPlansForState(lastEvaluatedKey.current)
    }
    
    setLiveLoading(false);
  }
  
  useEffect(() => {
    getPlansForState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const startPlan = async () => {
    setLoading(true);
    
    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const getPlansByStateIdUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans`;

      const metadataResponse = await fetch(getPlansByStateIdUrl, {
        method: 'POST',
        headers: {
          Authorization: accessToken,
        },
        body: '{}'
      });

      if (metadataResponse.status >= 300) {
        throw new Error('error from API');
      }

      const plan = await metadataResponse.json();

      history.push(`/organizations/${organizationId}/states/${stateId}/plans/${plan.id}`);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  return <>
    <div className='mb-3'><StateDetails /></div>
    <div>{ loading ? <Spinner/> : <div className='mb-5'>
    <div className="d-flex justify-content-between">
      <h3>All Plans</h3>
      <div>
        <Button color='primary' className='mt-2 mb-2' outline onClick={async () => {
          await startPlan()
        }}><FontAwesomeIcon icon={faBolt}/> Start New Plan</Button>
      </div>
    </div>
    {
      plans.length === 0 ? (
        <tr><td>No plans exist. <Link href="#" onClick={async () => {
          await startPlan()
        }}>
          Start a new one.
        </Link></td></tr>
      ) : undefined
    }
    <Row>{
      plans.map(plan => (
        <Col key={plan.id} md="12" className="mb-3">
          <PlanCard plan={plan} />
        </Col>
      ))
    }</Row>
    { showLoadMore ? <Button color='primary' disabled={liveLoading} outline onClick={async () => await loadMore()}>Load more</Button> : undefined }
    </div> }</div>
  </> ;
}

export default PlansPage;
