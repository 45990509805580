import React  from 'react';
import {BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import {getOrgFromPath} from "../../../helpers/ids";

function OrganizationMembersBreadcrumb () {
  return <BreadcrumbItem><Link to={`/organizations/${getOrgFromPath()}/members`}>Members</Link></BreadcrumbItem>
}

export default OrganizationMembersBreadcrumb;
