import React from "react";
import SlackNotifications
  from "../../components/slack-notifications";


function StateNotificationsPage() {
  return <div>
    <h3>Notifications</h3>
    <SlackNotifications />
  </div>
}

export default StateNotificationsPage;
