import React, {
  useEffect,
  useState
} from 'react';

import {
  Link,
  useParams
} from 'react-router-dom';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  Badge,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Spinner
} from "reactstrap";

function OrganizationsMembersPage() {
  const { organizationId } = useParams();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsersForOrganization = async () => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getOrganizationsByIdUrl = `https://${domain}/organizations/${organizationId}/users`;

        const metadataResponse = await fetch(getOrganizationsByIdUrl, {
          headers: {
            Authorization: accessToken,
          },
        });
        
        if (metadataResponse.status >= 300) {
          throw new Error('error from API');
        }

        const users = await metadataResponse.json();

        setUsers(users);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getUsersForOrganization();
  }, [getAccessTokenSilently, organizationId]);
  
  const removeUserFromOrganization = async (userId) => {
    setLoading(true);
    
    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const removeUserFromOrganizationUrl = `https://${domain}/organizations/${organizationId}/users/${userId}`;

      const metadataResponse = await fetch(removeUserFromOrganizationUrl, {
        method: 'DELETE',
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('error from API');
      }

      setUsers(users.filter(user => user.id !== userId));

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  
  return loading ? <Spinner/> : <div>
    <h3>Organization Members</h3>
    <p><Link to={`/organizations/${organizationId}/members/new`}>Add a member</Link> to your organization and assign them roles that let them perform actions in your states.</p>
    <Row>{
      users.map(user => (
        <Col key={user.id} md="12" className="mb-3">
          <Card>
            <CardBody>
              <CardTitle tag="h5">
                {user.email}
                {user.isOrganizationAdmin ? <Badge className='ml-1' color='warning'>Org Admin</Badge> : null}
                {user.roles.map(role => <Badge className='ml-1' key={role} color='primary'>{role}</Badge>)}
              </CardTitle>
              
              <CardText><Link href="#" onClick={async () => {
                await removeUserFromOrganization(user.id)
              }}>
                Remove
              </Link></CardText>
            </CardBody>
          </Card>
        </Col>
      ))
    }</Row>
    
  </div>;
}

export default OrganizationsMembersPage;
