import React, {
  useEffect,
  useState
} from 'react';
import {BreadcrumbItem} from "reactstrap";
import {
  Link,
  useLocation
} from "react-router-dom";
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  getOrgFromPath,
  getStateFromPath
} from "../../../helpers/ids";

function StateBreadcrumb () {
  const { getAccessTokenSilently } = useAuth0();
  
  const location = useLocation();
  
  const [state, setState] = useState();
  
  useEffect(() => {
    const loadState = async (organizationId, id) => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getStateByIdUrl = `https://${domain}/organizations/${organizationId}/states/${id}`;

        const metadataResponse = await fetch(getStateByIdUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        const st = await metadataResponse.json();

        setState(st);

      } catch (error) {
        console.log(error);
      }
    };

    (async () => {
      const stateId = getStateFromPath(location.pathname);

      // runs on location, i.e. route, change
      if (!stateId || stateId.length < 36) {
        setState(null);
      } else {
        await loadState(getOrgFromPath(), stateId);
      }
    })();
  }, [location.pathname, getAccessTokenSilently]);
  
  return state ? <BreadcrumbItem><Link to={`/organizations/${getOrgFromPath()}/states/${state.id}`}>{state.name}</Link></BreadcrumbItem> : <BreadcrumbItem>...</BreadcrumbItem>
}

export default StateBreadcrumb;
