import React, {
  useEffect,
  useRef,
  useState
} from "react";

import Organizations
  from "../../pages/organizations";
import NewOrganization
  from "../../pages/new-organization";
import OrganizationsMembersPage
  from "../../pages/organization-members";
import NewOrganizationMemberPage
  from "../../pages/new-organization-member";

import OrganizationBreadcrumb
  from "./organization-breadcrumb";
import OrganizationsBreadcrumb
  from "./organizations-breadcrumb";
import OrganizationMembersBreadcrumb
  from "./organization-members-breadcrumb";
import StatesPage
  from "../../pages/states";
import OrganizationTfTokenPage
  from "../../pages/organization-tf-token";
import OrganizationVersionControlPage
  from "../../pages/organization-version-control";
import NewStatePage
  from "../../pages/new-state";
import StatesBreadcrumb
  from "./states-breadcrumb";
import StateNotificationsPage
  from "../../pages/state-notifications";
import StateBreadcrumb
  from "./state-breadcrumb";
import {BreadcrumbItem, Breadcrumb} from "reactstrap";
import {
  matchPath,
  useLocation
} from "react-router-dom";
import PlansPage
  from "../../pages/plans";
import PlanDetailsPage
  from "../../pages/plan-details";
import StateEnvironmentPage
  from "../../pages/state-environment";
import NewEnvironmentVariablePage
  from "../../pages/new-environment-variable";
import EnvironmentVariablesBreadcrumb
  from "./environment-variables-breadcrumb";
import StateSettings
  from "../../pages/state-settings";

const authedRoutes = [
  {
    route: '/organizations',
    name: 'Organizations',
    component: Organizations,
    parents: []
  },
  {
    route: '/organizations/new',
    name: 'New',
    component: NewOrganization,
    parents: [OrganizationsBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/members',
    name: 'Members',
    component: OrganizationsMembersPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/members/new',
    name: 'New Organization Member',
    component: NewOrganizationMemberPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, OrganizationMembersBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states',
    name: 'States',
    component: StatesPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/tf-token',
    name: 'Terraform Cloud Token',
    component: OrganizationTfTokenPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/version-control',
    name: 'Version Control',
    component: OrganizationVersionControlPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/new',
    name: 'New State',
    component: NewStatePage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/:stateId/notifications',
    name: 'Notifications',
    component: StateNotificationsPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb, StateBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/:stateId/environment-variables',
    name: 'Environment Variables',
    component: StateEnvironmentPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb, StateBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/:stateId/environment-variables/new',
    name: 'New Environment Variable',
    component: NewEnvironmentVariablePage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb, StateBreadcrumb, EnvironmentVariablesBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/:stateId',
    component: PlansPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb, StateBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/:stateId/plans/:planId',
    name: 'Plan Details',
    component: PlanDetailsPage,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb, StateBreadcrumb]
  },
  {
    route: '/organizations/:organizationId/states/:stateId/settings',
    name: 'Settings',
    component: StateSettings,
    parents: [OrganizationsBreadcrumb, OrganizationBreadcrumb, StatesBreadcrumb, StateBreadcrumb]
  }
];

function BreadcrumbsComponent () {
  const location = useLocation();
  
  const [activeComponent, setActiveComponent] = useState();
  
  const currentPath = useRef('');
  
  useEffect(() => {
    const path = matchPath(location.pathname, {
      path: authedRoutes.map(route => route.route),
      exact: true
    });
    
    if (path && path.path !== currentPath.current) {
      setActiveComponent(authedRoutes.find(route => route.route === path.path));
    } else if (path && path.path !== currentPath.current) {
      setActiveComponent(undefined);
    } else if (path) {
      currentPath.current = path.path;
    } else {
      setActiveComponent(undefined);
      currentPath.current = '';
    }
  }, [location]);
  
  return activeComponent ? <Breadcrumb listClassName='bg-white border border-secondary'>
    { activeComponent ? activeComponent.parents.map(Parent => 
      <Parent key={Parent} />
    ) : <div/> }
    { activeComponent && activeComponent.name ? <BreadcrumbItem>{activeComponent.name}</BreadcrumbItem> : undefined }
  </Breadcrumb> : <div/>
}

export { authedRoutes };

export default BreadcrumbsComponent;
