import React, {
  useEffect,
  useState
} from 'react';

import {
  Col,
  Row,
  Spinner
} from 'reactstrap';
import {
  Link,
  useParams
} from "react-router-dom";
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {formatRelative} from "date-fns";
import PlanCard
  from "../plan-card";
import StateStatistics
  from "../state-statistics";

function StateDetails() {
  const { organizationId, stateId } = useParams();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [mostRecentlyFinishedPlan, setMostRecentlyFinishedPlan] = useState();
  const [recentlyCompletedPlans, setRecentlyCompletedPlans] = useState([]);
  const {
    getAccessTokenSilently
  } = useAuth0();
  
  useEffect(() => {
    
    (async () => {

      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getStateUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}`;

        const metadataResponse = await fetch(getStateUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        if (metadataResponse.status >= 300) {
          throw new Error('error from API');
        }

        const fetchedState = (await metadataResponse.json());

        setState(fetchedState);
        setCurrentPlan(fetchedState.currentPlan);
        
        if (fetchedState.recentlyCompletedPlans && fetchedState.recentlyCompletedPlans.length > 0) {
          setMostRecentlyFinishedPlan(fetchedState.recentlyCompletedPlans[0]);
          setRecentlyCompletedPlans(fetchedState.recentlyCompletedPlans);
        }
        
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, [getAccessTokenSilently, organizationId, stateId])
  
  return loading || !state ? <Spinner/> : 
    <div>
      <h3>{state.name}</h3>
      <h6>Created {formatRelative(new Date(state.createdAt), new Date())}</h6>
      <p className='d-flex justify-content-start flex-wrap'>
        <Link className='mr-5' to={`/organizations/${organizationId}/states/${stateId}/notifications`}>Notifications</Link>
        <Link className='mr-5' to={`/organizations/${organizationId}/states/${stateId}/environment-variables`}>Environment Variables</Link>
        <Link className='mr-5' to={`/organizations/${organizationId}/states/${stateId}/settings`}>Settings</Link>
      </p>
      <Row>
        { currentPlan ? <Col md='6' sm='12' className='mb-3'>
          <PlanCard plan={currentPlan} title='Current Plan' />
        </Col> : undefined }
        { mostRecentlyFinishedPlan ? <Col md='6' sm='12' className='mb-3'>
          <PlanCard plan={mostRecentlyFinishedPlan} title='Most Recent Plan' />
        </Col> : undefined }
        { recentlyCompletedPlans.length > 0 || true ? <Col md='12'>
          <StateStatistics
            recentlyCompletedPlans={recentlyCompletedPlans}
          />
        </Col> : <div/> }
      </Row>
      
  </div>

}

export default StateDetails;
