import {matchPath} from "react-router-dom";

const getOrgFromPath = () => {
  const orgMatch = matchPath(window.location.pathname, {
    path: '/organizations/:organizationId'
  });

  let organizationId = null;

  if (orgMatch) {
    organizationId = orgMatch.params.organizationId;
  }
  return organizationId;
}

const getStateFromPath = () => {
  const orgMatch = matchPath(window.location.pathname, {
    path: '/organizations/:organizationId/states/:stateId'
  });

  let stateId = null;

  if (orgMatch) {
    stateId = orgMatch.params.stateId;
  }
  return stateId;
}

export { getOrgFromPath, getStateFromPath }
