import React from "react";

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBolt,
  faDollarSign,
  faUsers,
  faLock,
  faPlus,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

function HomePage() {
  // const [status, setStatus] = useState('PLAN_IN_PROGRESS');
  // const statusRef = useRef('PLAN_IN_PROGRESS');
  //
  // const statuses = ['PLAN_IN_PROGRESS', 'AWAITING_APPROVAL', 'APPLY_IN_PROGRESS', 'COMPLETED'];
  //
  // useEffect(() => {
  //   const ref = setInterval(() => {
  //     let statusIndex = statuses.indexOf(statusRef.current) + 1;
  //    
  //     if (statusIndex === statuses.length) {
  //       statusIndex = 0;
  //     }
  //    
  //     setStatus(statuses[statusIndex]);
  //     statusRef.current = statuses[statusIndex];
  //   }, 10000)
  //  
  //   return () => {
  //     clearInterval(ref);
  //   }
  // }, [statuses]);
  
  return <div>
    <div className='bg-dark text-light'>
      <Container>
        <div className='d-flex justify-content-center align-items-center flex-column' style={{
          height: '60vh',
          marginBottom: '5vh'
        }}>
          <div className='mb-3 d-flex flex-column align-items-center'>
            <h1 className='text-center'>The <span className='text-warning'>smart way</span> to manage your cloud</h1>
            <h3 className='text-center mb-4'>Automated infrastructure as code workflows for the discerning builder.</h3>
            <div className='d-flex flex-row'>
              <a className='btn btn-primary btn-lg' href={'mailto:me@ericzaporzan.com?subject=Brainiac early access request&body=Thanks for your interest in Brainiac! Below, please describe your use case and your rough expected usage (team size, number of workspaces, etc.):'}>Get Access</a>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <Container>
      <h3 className='mb-4'>Run Terraform in automation</h3>
      <p>Realize the benefits of shifting your Terraform workflows from your laptop to the cloud</p>
      <Row>
        <Col md='6' className='mb-2'>
          <h5><FontAwesomeIcon className='text-primary mr-2' icon={faBolt} />Easy</h5>
          <p>Connect your project and start planning in seconds. Github supported now, other providers coming soon.</p>
        </Col>
        <Col md='6' className='mb-2'>
          <h5><FontAwesomeIcon className='text-primary mr-2' icon={faUsers} />Collaborative</h5>
          <p>Repeatable, reliable plans, without all the hassle of running locally. Invite your collaborators and manage their access your way, at no extra cost. Large teams welcome.</p>
        </Col>
        <Col md='6' className='mb-2'>
          <h5><FontAwesomeIcon className='text-primary mr-2' icon={faLock} />Secure</h5>
          <p>Strong encryption, isolated environments, role-based access, auditable history. Don't compromise on security.</p>
        </Col>
        <Col md='6' className='mb-2'>
          <h5><FontAwesomeIcon className='text-primary mr-2' icon={faDollarSign} />Affordable</h5>
          <p>A complete feature-set at a fraction of the cost. No commitments; only pay for what you use.</p>
        </Col>
      </Row>
      <h4 className='mb-4'>Pricing</h4>
      <Row>
        <Col md='4'>
          <Card className='h-100 card-secondary mb-2'>
            <CardBody>
              <CardTitle tag="h5"><FontAwesomeIcon className='text-primary mr-2' icon={faCheck} />Always Included</CardTitle>
              <CardText>
                <ul>
                  <li>Plan insights</li>
                  <li>Team management (unlimited users)</li>
                  <li>Audit logs (30 day retention)</li>
                </ul>
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col md='4'>
          <Card className='h-100 card-secondary mb-2'>
            <CardBody>
              <CardTitle tag="h5"><FontAwesomeIcon className='text-primary mr-2' icon={faBolt} />Standard</CardTitle>
              <CardText>
                <ul>
                  <li>1 USD per 250 minutes of plan/apply time (minimum billed runtime per plan/apply is one minute), billed to the nearest second.</li>
                  <li>No commitments, no minimum usage.</li>
                </ul>
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col md='4'>
          <Card className='h-100 card-secondary mb-2'>
            <CardBody>
              <CardTitle tag="h5"><FontAwesomeIcon className='text-primary mr-2' icon={faPlus} />Custom</CardTitle>
              <CardText>Need self-hosted? Bigger instance types? Custom features? <a href='mailto:me@ericzaporzan.com'>Get in touch</a> and we'll get you sorted.</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <p className='mt-5'>Ready to go? Brainiac is currently in closed beta. <a href='mailto:me@ericzaporzan.com'>Reach out</a> if you want to give it a try!</p>
      <p>© Copyright { new Date().getFullYear() } <a href="http://ericzaporzan.com">Eric Zaporzan</a>. Offered as-is and without warranty.</p>
    </Container>
  </div>
}

export default HomePage;
