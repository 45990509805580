import React, { useState } from "react";

import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {Spinner} from 'reactstrap';

function NewEnvironmentVariablePage() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [value, setValue] = useState('');
  const [encrypted, setEncrypted] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { organizationId, stateId } = useParams();

  const {
    getAccessTokenSilently
  } = useAuth0();

  const onSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });
      
      const addEnvironmentVariableUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/environment-variables`;

      console.log({
        name,
        description,
        value,
        encrypted
      });
      const metadataResponse = await fetch(addEnvironmentVariableUrl, {
        method: 'POST',
        body: JSON.stringify({
          name,
          description,
          value,
          encrypted
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      history.push(`/organizations/${organizationId}/states/${stateId}/environment-variables`);

    } catch (error) {
      setLoading(false);
      
      console.log(error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  }

  const handleValueChange = (e) => {
    setValue(e.target.value);
  }

  const handleEncryptedChange = (e) => {
    setEncrypted(e.target.checked);
  }

  return loading ? <Spinner/> : <div>
    <h3>New Environment Variable</h3>
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="name">Name</Label>
        <Input onChange={handleNameChange} value={name} type="text" name="name" id="name" placeholder="Name as it will be exported" />
      </FormGroup>
      <FormGroup>
        <Label for="description">Description</Label>
        <Input onChange={handleDescriptionChange} value={description} type="textarea" name="description" id="description" placeholder="What it does, whose account generated it, etc." />
      </FormGroup>
      <FormGroup>
        <Label for="value">Value</Label>
        <Input onChange={handleValueChange} value={value} type={encrypted ? "password" : "text"} name="value" id="value" placeholder="Value of the variable" />
      </FormGroup>
      <FormGroup check>
        <Label for="encrypted">
          <Input name="encrypted" id="encrypted" onChange={handleEncryptedChange} type="checkbox" />
          Encrypted?
        </Label>
      </FormGroup>
      <Button>Create</Button>
    </Form>
  </div>
}

export default NewEnvironmentVariablePage;
