import React, { useState } from "react";

import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {Spinner} from 'reactstrap';
import StateSettingsForm
  from "../../components/state-settings-form";

function NewStatePage() {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { organizationId } = useParams();

  const {
    getAccessTokenSilently
  } = useAuth0();

  const onSubmit = async (values) => {
    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const readRolesList = values.readRoles.trim() ? values.readRoles.split(",").map(role => role.trim()) : [];
      const planRolesList = values.planRoles.trim() ? values.planRoles.split(",").map(role => role.trim()) : [];
      const applyRolesList = values.applyRoles.trim() ? values.applyRoles.split(",").map(role => role.trim()) : [];
      const adminRolesList = values.adminRoles.trim() ? values.adminRoles.split(",").map(role => role.trim()) : [];

      const addStateUrl = `https://${domain}/organizations/${organizationId}/states`;

      const metadataResponse = await fetch(addStateUrl, {
        method: 'POST',
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          repoSlug: values.repoSlug,
          workingDirectory: values.workingDirectory,
          terraformVersion: values.terraformVersion,
          readRoles: readRolesList,
          planRoles: planRolesList,
          applyRoles: applyRolesList,
          adminRoles: adminRolesList
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      const state = await metadataResponse.json();
      
      console.log(state);

      history.push(`/organizations/${organizationId}/states/${state.id}`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return loading ? <Spinner/> : <div>
    <h3>New State</h3>
    <StateSettingsForm
      submitButtonText={'Create'}
      onSubmit={onSubmit}
    />
  </div>
}

export default NewStatePage;
