import React, {
  useState
} from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner
} from "reactstrap";
import {useFormik} from "formik";
import {
  useMakeRequest,
  useMountEffect
} from "../../helpers/request";

// Fallback in case endpoint is broken
const _terraformVersions = [
  '1.1.6',
  '1.1.5',
  '1.1.4',
  '1.1.3',
  '1.1.2',
  '1.1.1',
  '1.1.0',
  '1.0.9',
  '1.0.8',
  '1.0.7',
  '1.0.6',
  '1.0.5',
  '1.0.4',
  '1.0.3',
  '1.0.2',
  '1.0.1',
  '1.0.0',
  '0.15.5',
  '0.15.4',
  '0.15.3',
  '0.15.2',
  '0.15.1',
  '0.15.0',
  '0.14.11',
  '0.14.10',
  '0.14.9',
  '0.14.8',
  '0.14.7',
  '0.14.6',
  '0.14.5',
  '0.14.4',
  '0.14.3',
  '0.14.2',
  '0.14.1',
  '0.14.0',
  '0.13.5',
  '0.13.4',
  '0.13.3',
  '0.13.2',
  '0.13.1',
  '0.13.0',
  '0.12.29',
  '0.12.28',
  '0.12.27',
  '0.12.26',
  '0.12.25',
  '0.12.24',
  '0.12.23',
  '0.12.22',
  '0.12.21',
  '0.12.20',
  '0.12.19',
  '0.12.18',
  '0.12.17',
  '0.12.16',
  '0.12.15',
  '0.12.14',
  '0.12.13',
  '0.12.12',
  '0.12.11',
  '0.12.10',
  '0.12.9',
  '0.12.8',
  '0.12.7',
  '0.12.6',
  '0.12.5',
  '0.12.4',
  '0.12.3',
  '0.12.2',
  '0.12.1',
  '0.12.0',
  '0.11.14',
  '0.11.13',
  '0.11.12',
  '0.11.11',
  '0.11.10',
  '0.11.9',
  '0.11.8',
  '0.11.7',
  '0.11.6',
  '0.11.5',
  '0.11.4',
  '0.11.3',
  '0.11.2',
  '0.11.1',
  '0.11.0'
];

function StateSettingsForm(props) {
  const [loading, setLoading] = useState(true);
  const [terraformVersions, setTerraformVersions] = useState(_terraformVersions);
  const makeRequest = useMakeRequest();

  useMountEffect(() => {
    (async () => {
      try {
        const result = await makeRequest({
          path: '/terraform-versions',
          method: 'GET'
        })
        setTerraformVersions(result.versions);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  });
  
  const formik = useFormik({
    initialValues: {
      name: '',
      repoSlug: '',
      workingDirectory: '',
      terraformVersion: terraformVersions[0],
      description: '',
      readRoles: '',
      planRoles: '',
      applyRoles: '',
      adminRoles: '',
      ...(props.initialValues || {})
    },
    onSubmit: values => {
      props.onSubmit(values);
    }
  });

  return loading ? <Spinner /> : <Container>
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input onChange={formik.handleChange} value={formik.values.name} type="text" name="name" id="name" placeholder="Give it a descriptive name" />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="repoSlug">Repo Slug</Label>
            <Input onChange={formik.handleChange} value={formik.values.repoSlug} type="text" name="repoSlug" id="repoSlug" placeholder="Github repo slug, e.g. 'hashicorp/terraform'" />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="workingDirectory">Working Directory</Label>
            <Input onChange={formik.handleChange} value={formik.values.workingDirectory} type="text" name="workingDirectory" id="workingDirectory" placeholder="Directory for the state, e.g. 'states/develop'. If it's at the root of the project, leave this blank." />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="terraformVersion">Terraform Version</Label>
            <Input onChange={formik.handleChange} value={formik.values.terraformVersion} type="select" name="terraformVersion" id="terraformVersion">
              {
                terraformVersions.map(version => <option key={version}>{version}</option>)
              }
            </Input>
          </FormGroup>
        </Col>
        <Col md={12}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input onChange={formik.handleChange} value={formik.values.description} type="textarea" name="description" id="description" placeholder="What it does, who it's for, etc." />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="readRoles">Read Roles</Label>
            <Input onChange={formik.handleChange} value={formik.values.readRoles} type="text" name="readRoles" id="readRoles" placeholder="List of roles that can view this state, comma-separated." />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="planRoles">Plan Roles</Label>
            <Input onChange={formik.handleChange} value={formik.values.planRoles} type="text" name="planRoles" id="planRoles" placeholder="List of roles that can plan this state, comma-separated." />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="applyRoles">Apply Roles</Label>
            <Input onChange={formik.handleChange} value={formik.values.applyRoles} type="text" name="applyRoles" id="applyRoles" placeholder="List of roles that can apply this state, comma-separated." />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="adminRoles">Admin Roles ('admin' is added by default)</Label>
            <Input onChange={formik.handleChange} value={formik.values.adminRoles} type="text" name="adminRoles" id="adminRoles" placeholder="List of roles that can administer this state, comma-separated." />
          </FormGroup>
        </Col>
        <Col md={12}>
          <Button color='primary'>{props.submitButtonText || 'Create'}</Button>
        </Col>
      </Row>
    </Form>
  </Container>
  
}

export default StateSettingsForm;
