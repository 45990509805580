import React, { useState } from "react";

import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  useHistory,
  useParams
} from "react-router-dom";
import {Spinner} from 'reactstrap';

function OrganizationVersionControlPage() {
  const [githubToken, setGithubToken] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { organizationId } = useParams();

  const {
    getAccessTokenSilently
  } = useAuth0();

  const onSubmit = async (e) => {
    e.preventDefault();
    
    setLoading(true);

    const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

    try {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://auth0-jwt-authorizer'
      });

      const addOrUpdateGithubTokenUrl = `https://${domain}/organizations/${organizationId}/github-token`;

      const metadataResponse = await fetch(addOrUpdateGithubTokenUrl, {
        method: 'POST',
        body: JSON.stringify({
          token: githubToken
        }),
        headers: {
          Authorization: accessToken,
        },
      });

      if (metadataResponse.status >= 300) {
        throw new Error('Bad status code from the API');
      }

      history.push(`/organizations/${organizationId}/states`);

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleTokenChange = (e) => {
    setGithubToken(e.target.value);
  }

  return loading ? <Spinner/> : <div>
    <h3>Version Control</h3>
    <p>Connect your Brainiac organization to Github using a Personal Access Token</p>
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label for="github-token">Github Token</Label>
        <Input onChange={handleTokenChange} value={githubToken} type="password" name="github-token" id="github-token" placeholder="Github Token" />
      </FormGroup>
      <Button>Update</Button>
    </Form>
  </div>
}

export default OrganizationVersionControlPage;
