import React, { useEffect, useState } from 'react';

import {
  Link,
  useParams
} from 'react-router-dom';
import fetch
  from "node-fetch";
import {useAuth0} from "@auth0/auth0-react";
import {
  Badge,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardSubtitle,
  Row,
  Col,
  Button
} from "reactstrap";
import {
  getPlan,
  getPlanStatusText,
  getPropsForStatus
} from "../../helpers/plan";
import {formatRelative} from "date-fns";

function StatesPage() {
  const { organizationId } = useParams();
  const {
    getAccessTokenSilently
  } = useAuth0();
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStatesForOrganization = async () => {
      const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

      try {
        const accessToken = await getAccessTokenSilently({
          audience: 'https://auth0-jwt-authorizer'
        });

        const getStatesByOrganizationIdUrl = `https://${domain}/organizations/${organizationId}/states`;

        const metadataResponse = await fetch(getStatesByOrganizationIdUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        if (metadataResponse.status >= 300) {
          throw new Error('error from API');
        }

        const states = await metadataResponse.json();
        
        for (const state of states) {
          if (state.currentPlanId) {
            state.currentPlan = await getPlan({
              accessToken,
              organizationId,
              stateId: state.id,
              planId: state.currentPlanId,
              includePlanLogs: false,
              includeApplyLogs: false,
              afterChunk: 0
            });
          }
        }

        setStates(states.filter(s => !s.isMarkedForDeletion));
        setLoading(false);
        
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getStatesForOrganization();
  }, [getAccessTokenSilently, organizationId]);
  
  const currentDate = new Date();

  return loading ? <Spinner/> : <div>
    <div className="d-flex justify-content-between">
      <h3>States</h3>
      <div>
        <Button color='primary' className='mt-2 mb-2' tag={Link} outline to={`/organizations/${organizationId}/states/new`}>New State</Button>
      </div>
    </div>
    
    {
      states.length === 0 ? (
        <p>Either no states exist, or you don't have permission to access any right now. Contact your administrator if you need increased access.</p>
      ) : undefined
    }
    <Row>{
      states.map(state => (
        <Col key={state.id} md="6" className="mb-3">
          <Card>
            <CardBody>
              <CardTitle tag="h5"><Link to={`/organizations/${organizationId}/states/${state.id}`}>{state.name}</Link> 
                <Badge className='ml-1' tag='p' color="info" pill>v{state.terraformVersion}</Badge>
                { state.currentPlan ? <Badge className='ml-1' tag={Link} color={getPropsForStatus(state.currentPlan.status).color} pill to={`/organizations/${organizationId}/states/${state.id}/plans/${state.currentPlan.id}`}>{getPlanStatusText(state.currentPlan.status)}</Badge> : undefined }
              </CardTitle>
              <CardSubtitle tag="h6" className="mb-2 text-muted">Last activity {formatRelative(new Date(state.modifiedAt), currentDate)}</CardSubtitle>
              <CardText><Link to={`/organizations/${organizationId}/states/${state.id}/settings`}>Settings</Link></CardText>
              <CardText><Link to={`/organizations/${organizationId}/states/${state.id}/environment-variables`}>Environment Variables</Link></CardText>
              <CardText><Link to={`/organizations/${organizationId}/states/${state.id}/notifications`}>Notifications</Link></CardText>
            </CardBody>
          </Card>
        </Col>
      ))
    }</Row>

  </div>;
}

export default StatesPage;
