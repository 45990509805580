import fetch
  from "node-fetch";

const domain = "lwfsxe9gs2.execute-api.us-west-2.amazonaws.com";

const getPlanStatusText = (planStatus) => {
  switch (planStatus) {
    case 'QUEUED': 
      return 'Queued';
    case 'PLAN_IN_PROGRESS':
      return 'Plan in progress';
    case 'AWAITING_APPROVAL':
      return 'Awaiting approval';
    case 'APPLY_IN_PROGRESS':
      return 'Apply in progress';
    case 'COMPLETED':
      return 'Completed';
    case 'PLAN_FAILED': 
      return 'Plan failed';
    case 'APPLY_FAILED': 
      return 'Apply failed';
    case 'DISCARDED': 
      return 'Discarded';
    case 'NO_CHANGES':
      return 'No changes';
    default:
      return 'Unknown status';
  }
}

const getPropsForStatus = (status) => {
  switch (status) {
    case 'QUEUED':
      return {
        value: 10,
        message: 'Queued',
        color: 'secondary',
        animated: false,
        isFinalState: false
      }
    case 'PLAN_IN_PROGRESS':
      return {
        value: 25,
        message: 'Plan in progress',
        color: 'primary',
        animated: true,
        isFinalState: false
      }
    case 'AWAITING_APPROVAL':
      return {
        value: 50,
        message: 'Awaiting approval',
        color: 'warning',
        animated: true,
        isFinalState: false
      }
    case 'APPLY_IN_PROGRESS':
      return {
        value: 75,
        message: 'Apply in progress',
        color: 'primary',
        animated: true,
        isFinalState: false
      }
    case 'COMPLETED':
      return {
        value: 100,
        message: 'Apply complete',
        color: 'success',
        animated: false,
        isFinalState: true
      };
    case 'PLAN_FAILED':
      return {
        value: 25,
        message: 'Plan failed',
        color: 'danger',
        animated: false,
        isFinalState: true
      }
    case 'APPLY_FAILED':
      return {
        value: 75,
        message: 'Apply failed',
        color: 'danger',
        animated: false,
        isFinalState: true
      }
    case 'DISCARDED':
      return {
        value: 50,
        message: 'Discarded',
        color: 'secondary',
        animated: false,
        isFinalState: true
      }
    case 'NO_CHANGES':
      return {
        value: 100,
        message: 'No changes',
        color: 'success',
        animated: false,
        isFinalState: true
      }
    default:
      return {
        value: 50,
        message: 'Unknown status',
        color: 'secondary',
        animated: false,
        isFinalState: false
      }
  }
}

const getPlan = async (args) => {
  const { organizationId, stateId, planId, afterChunk, includePlanLogs, includeApplyLogs, accessToken } = args

  const afterChunkClause = `?afterChunk=${afterChunk || 0}`;
  const includePlanLogsClause = `&includePlanLogs=${includePlanLogs || false}`;
  const includeApplyLogsClause = `&includeApplyLogs=${includeApplyLogs || false}`;
  
  const getPlanLogsUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans/${planId}/logs${afterChunkClause}${includePlanLogsClause}${includeApplyLogsClause}`;

  const metadataResponse = await fetch(getPlanLogsUrl, {
    headers: {
      Authorization: accessToken,
    },
  });

  if (metadataResponse.status >= 300) {
    throw new Error('error from API');
  }

  return metadataResponse.json();
}

const applyPlan = async (organizationId, stateId, planId, accessToken) => {
  const applyPlanUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans/${planId}/apply`;

  const metadataResponse = await fetch(applyPlanUrl, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
    },
    body: '{}'
  });

  if (metadataResponse.status >= 300) {
    throw new Error('error from API');
  }

  return metadataResponse.json();
}

const discardPlan = async (organizationId, stateId, planId, accessToken) => {
  const discardPlanUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans/${planId}/discard`;

  const metadataResponse = await fetch(discardPlanUrl, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
    },
    body: '{}'
  });

  if (metadataResponse.status >= 300) {
    throw new Error('error from API');
  }

  return metadataResponse.json();
}

const retryPlan = async (organizationId, stateId, planId, accessToken) => {
  const discardPlanUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans/${planId}/retry`;

  const metadataResponse = await fetch(discardPlanUrl, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
    },
    body: '{}'
  });

  if (metadataResponse.status >= 300) {
    throw new Error('error from API');
  }

  return metadataResponse.json();
}

const preemptPlan = async (organizationId, stateId, planId, accessToken) => {
  const preemptPlanUrl = `https://${domain}/organizations/${organizationId}/states/${stateId}/plans/${planId}/preempt`;

  const metadataResponse = await fetch(preemptPlanUrl, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
    },
    body: '{}'
  });

  if (metadataResponse.status >= 300) {
    throw new Error('error from API');
  }

  return metadataResponse.json();
}

export { getPlanStatusText, getPropsForStatus, getPlan, applyPlan, discardPlan, retryPlan, preemptPlan };
